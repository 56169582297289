import { Box, Grid, Flex, Text, Link, VStack, Skeleton, Image, useColorModeValue } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import React from 'react';

import type { CustomLinksGroup } from 'types/footerLinks';

import config from 'configs/app';
import discussionsIcon from 'icons/discussions.svg';
import editIcon from 'icons/edit.svg';
import cannyIcon from 'icons/social/canny.svg';
import discordIcon from 'icons/social/discord.svg';
import gitIcon from 'icons/social/git.svg';
import twitterIcon from 'icons/social/twitter.svg';
import donateIcon from 'icons/social/donate.svg';
import telegramIcon from 'icons/social/telega.svg';
import type { ResourceError } from 'lib/api/resources';
import useApiQuery from 'lib/api/useApiQuery';
import useFetch from 'lib/hooks/useFetch';
import useIssueUrl from 'lib/hooks/useIssueUrl';
import IndexingAlertIntTxs from 'ui/home/IndexingAlertIntTxs';
import NetworkAddToWallet from 'ui/shared/NetworkAddToWallet';

import ColorModeToggler from '../header/ColorModeToggler';
import FooterLinkItem from './FooterLinkItem';
import getApiVersionUrl from './utils/getApiVersionUrl';

const MAX_LINKS_COLUMNS = 3;

const FRONT_VERSION_URL = `https://github.com/blockscout/frontend/tree/${config.UI.footer.frontendVersion}`;
const FRONT_COMMIT_URL = `https://github.com/blockscout/frontend/commit/${config.UI.footer.frontendCommit}`;

const Footer = () => {

  const { data: backendVersionData } = useApiQuery('config_backend_version', {
    queryOptions: {
      staleTime: Infinity,
    },
  });
  // const apiVersionUrl = getApiVersionUrl(backendVersionData?.backend_version);
  // const issueUrl = useIssueUrl(backendVersionData?.backend_version);

  const BLOCKSCOUT_LINKS = [
    {
      icon: editIcon,
      iconSize: '16px',
      text: 'Submit Request',
      url: "",
    },
    {
      icon: cannyIcon,
      iconSize: '20px',
      text: 'Feature request',
      url: '',
    },
    {
      icon: gitIcon,
      iconSize: '18px',
      text: 'Contribute',
      url: 'https://github.com/',
    },
    {
      icon: twitterIcon,
      iconSize: '18px',
      text: 'Twitter',
      url: 'https://twitter.com/',
    },
    {
      icon: telegramIcon,
      iconSize: '18px',
      text: 'Telegram',
      url: '',
    },
    {
      icon: discussionsIcon,
      iconSize: '20px',
      text: 'Discussions',
      url: '',
    }
    // {
    //   icon: donateIcon,
    //   iconSize: '20px',
    //   text: 'Donate',
    //   url: '',
    //   border: '0.5px solid #7700F7',
    // }
  ];

  const frontendLink = (() => {
    if (config.UI.footer.frontendVersion) {
      return <Link href={FRONT_VERSION_URL} target="_blank">{config.UI.footer.frontendVersion}</Link>;
    }

    if (config.UI.footer.frontendCommit) {
      return <Link href={FRONT_COMMIT_URL} target="_blank">{config.UI.footer.frontendCommit}</Link>;
    }

    return null;
  })();

  const fetch = useFetch();

  const { isLoading, data: linksData } = useQuery<unknown, ResourceError<unknown>, Array<CustomLinksGroup>>(
    ['footer-links'],
    async () => fetch(config.UI.footer.links || ''),
    {
      enabled: Boolean(config.UI.footer.links),
      staleTime: Infinity,
    });

  return (
    <Flex
      direction={'column'}
    >

      <Flex
        direction={{ base: 'column', lg: 'row' }}
        p={{ base: 4, lg: 9 }}
        borderTop="1px solid"
        borderColor="divider"
        as="footer"
        columnGap="100px"
      >
        <Box flexGrow="1" mb={{ base: 8, lg: 0 }}>
          <Flex flexWrap="wrap" columnGap={8} rowGap={6}>
            <ColorModeToggler />
            {!config.UI.indexingAlert.isHidden && <IndexingAlertIntTxs />}
            <NetworkAddToWallet />
          </Flex>
          <Box mt={{ base: 5, lg: '25px' }} display={'flex'}>
            <Image
              src='/static/icon_rz.svg'
              width="30px" height="30px" mr={1}
            />
            <Text textColor={useColorModeValue('black', 'white')} fontSize="xl" as='b' ml={'10px'}> Powered by Rz</Text>
          </Box>
          {/* <Box mt={{ base: 5, lg: '10px' }}>
          <Link fontSize="xs" href="" color={"#DCB36F"}>Rz.game</Link>
        </Box> */}
          <Text mt={3} maxW={{ base: 'unset', lg: '470px' }} fontSize="xs">
            RZchain is a Block Explorer and Analytics platform for RZCoin (RZ).
          </Text>
          <VStack spacing={1} mt={6} alignItems="start">
            {frontendLink && (
              <Text fontSize="xs">
                Frontend: {frontendLink}
              </Text>
            )}
          </VStack>
        </Box>
        <Grid
          gap={{ base: 6, lg: 12 }}
          gridTemplateColumns={config.UI.footer.links ?
            { base: 'repeat(auto-fill, 160px)', lg: `repeat(${(linksData?.length || MAX_LINKS_COLUMNS) + 1}, 160px)` } :
            'auto'
          }
        >
          <Box minW="160px" w={config.UI.footer.links ? '160px' : '100%'}>
            {config.UI.footer.links && <Text fontWeight={500} mb={3}>Rz Explorer</Text>}
            <Grid
              gap={1}
              gridTemplateColumns={config.UI.footer.links ? '160px' : { base: 'repeat(auto-fill, 160px)', lg: 'repeat(4, 160px)' }}
              gridTemplateRows={{ base: 'auto', lg: config.UI.footer.links ? 'auto' : 'repeat(2, auto)' }}
              gridAutoFlow={{ base: 'row', lg: config.UI.footer.links ? 'row' : 'column' }}
              mt={{ base: 0, lg: config.UI.footer.links ? 0 : '100px' }}
            >
              {BLOCKSCOUT_LINKS.map(link => <FooterLinkItem {...link} key={link.text} />)}
            </Grid>
          </Box>
          {config.UI.footer.links && isLoading && (
            Array.from(Array(3)).map((i, index) => (
              <Box minW="160px" key={index}>
                <Skeleton w="120px" h="20px" mb={6} />
                <VStack spacing={5} alignItems="start" mb={2}>
                  {Array.from(Array(5)).map((i, index) => <Skeleton w="160px" h="14px" key={index} />)}
                </VStack>
              </Box>
            ))
          )}
          {config.UI.footer.links && linksData && (
            linksData.slice(0, MAX_LINKS_COLUMNS).map(linkGroup => (
              <Box minW="160px" key={linkGroup.title}>
                <Text fontWeight={500} mb={3}>{linkGroup.title}</Text>
                <VStack spacing={1} alignItems="start">
                  {linkGroup.links.map(link => <FooterLinkItem {...link} key={link.text} />)}
                </VStack>
              </Box>
            ))
          )}
        </Grid>
      </Flex>
      <Box
        borderTop="0.5px solid #ECECEC"
        p={{ base: 4, lg: 9 }}
        as="footer"
      >
        <Text maxW={{ base: 'unset', lg: '470px' }} fontSize="xs">
          RZchain © 2024 (BSC-C) | Built by Team <Text as={'b'}>RZchain</Text>
        </Text>
      </Box>
    </Flex>
  );
};

export default Footer;
